import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {ClientService} from '../../../clients.service';
import {ICheckIn} from '../../../../../models/check_in';
import {CheckInsProvider} from '../../../../../providers/check-ins.service';
import {HttpClient} from '@angular/common/http';
import {AwakenModal} from '../../../../../shared/awaken-modal/awaken-modal.component';
import {ModalController} from '@ionic/angular';
import {IBasicWeighIn, IWeighIn} from '../../../../../models/weigh-in';
import {GlobalsService} from '../../../../../globals.service';
import {PurchasesService} from '../finances/purchases.service';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import {GoalService} from '../../../../../components/goal/goal.service';
import {ProgramsService} from '../programs/programs.service';

const fields = [
  'current_weight',
  'body_fat',
  'muscle_mass',
  'visceral_fat',
  'fat_mass',
  'body_water_percent'
]

@Injectable()
export class WeighInsProvider implements OnDestroy {

  private weigh_ins = new BehaviorSubject([] as IWeighIn[]);
  weigh_ins$ = this.weigh_ins.asObservable();

  weighInsModified = new BehaviorSubject(true as boolean);
  weighInsModified$ = this.weighInsModified.asObservable()

  check_ins$: Observable<ICheckIn[]> = of([]);
  weightloss = 0;
  weightloss_pre_update = 0
  average_weightloss = 0;
  base_url: string;
  clientSubscription: Subscription;

  constructor( private global: GlobalsService,
               private checkInsProvider: CheckInsProvider,
               private http: HttpClient,
               private goalService: GoalService,
               private modalCtrl: ModalController,
               private programsService: ProgramsService,
               private purchasesProvider: PurchasesService,
               private clientService: ClientService) {

    this.base_url = this.global.base_url;
    this.check_ins$ = this.checkInsProvider.reversed_check_ins$
    console.log(this)
    this.weigh_ins$ = this.check_ins$.pipe(
      map( (arrayOfCheckIns) => {
        const arr = []
        arrayOfCheckIns.map( ci => {
          if (ci.weigh_in) arr.push(ci.weigh_in)
        })
        this.weigh_ins.next(arr)
        return arr
      })
    )

    this.calculateWeightloss()
    this.subscribeToClient()
    console.log(this)
  }

  ngOnDestroy(): void {
    if (this.clientSubscription) this.clientSubscription.unsubscribe()
  }

  subscribeToClient() {
    this.clientSubscription = this.clientService.client$.subscribe( client => {
      this.setWeightLoss(client.weightloss)
    })
  }

  async launchCompletedGoalsModals(completedScaleGoals) {
    for (const goal of completedScaleGoals) {
      if (goal.field) {
        if (goal.field === 'weight') {
          await this.launchCompletedGoalModal('It looks like they\'ve reached their weight goal!', goal)
        } else if (goal.field === 'body_fat') {
          await this.launchCompletedGoalModal('It looks like they\'ve reached their body fat goal!', goal)
        }
      }

      if (goal.goal_type === 'hbc') {
        await this.launchCompletedGoalModal('It looks like they\'ve reached HBC!', goal)
      }
    }
  }

  async launchCompletedGoalModal(title, incompleteGoal): Promise<any> {
    return new Promise(async(resolve) => {
      const modal = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title,
          subtitle: 'Would you like to mark this goal complete?',
          type: 'confirm',
        },
        cssClass: 'small-modal'
      })

      await modal.present()

      await modal.onDidDismiss().then(data => {
        resolve(null)

        if (data?.data === 'yes') {
          incompleteGoal.completed_at = moment().format()
          this.goalService.updateGoal(incompleteGoal).subscribe(
            () => this.global.handleResponse('Successfully marked the goal complete!', false, 'success'),
            err => this.global.handleResponse(err.error, true),
          )
        }
      })
    })
  }


  /**
   * Searches for unclaimed weigh-ins in the DB by the provided name
   * @param clientName The client's name
   */
  search(clientName: string): Observable<IBasicWeighIn[]> {
    const url = `${this.base_url}/weigh_ins/search?name=${clientName}`
    return this.http.get<IBasicWeighIn[]>(url)
  }


  // CRUD ---------------------------------------------------------------

  create(weighIn: IWeighIn) {
    const url = `${ this.base_url }/weigh_ins`
    this.weightloss_pre_update = this.weightloss

    return this.http.post<{weigh_in: IWeighIn, warning: {title: string, subtitle: string}}>(url, weighIn).pipe(
        tap(data => {
          const newWeighIn = data.weigh_in

          if (newWeighIn.client_id) {
            this.checkInsProvider.addWeighIn(newWeighIn)
            this.checkForCelebration(newWeighIn)
            this.checkForRepoCelebrations(newWeighIn.check_in_id)
            this.displayGoalReminder(newWeighIn.check_in_id)
            this.weighInsModified.next(true)
          }
        })
      )
    }


  /**
   * Updates the weigh in in the database.
   * @param weighIn Updated weigh in
   * @param requestSentFromClientInvite Whether or not this weigh-in was generated during the client invite flow
   */
  update(weighIn: IWeighIn, requestSentFromClientInvite: boolean = false) {
    const url = `${ this.base_url }/weigh_ins/${ weighIn.id }`
    this.weightloss_pre_update = this.weightloss

    return this.http.put<{weigh_in: IWeighIn, warning: {title: string, subtitle: string}}>(url, weighIn).pipe(
      tap( async updatedWeighIn => {
        if (!requestSentFromClientInvite) {
          this.checkInsProvider.addWeighIn(updatedWeighIn.weigh_in)
          await this.checkForCelebration(updatedWeighIn.weigh_in)
          await this.displayGoalReminder(updatedWeighIn.weigh_in.check_in_id)
        }
      })
    )
  }

  destroy(weighIn: IWeighIn) {
    const url = `${ this.base_url }/weigh_ins/${ weighIn.id }`
    return this.http.delete(url).pipe(
      tap(() => {
        this.checkInsProvider.removeWeighIn(weighIn)
        this.weighInsModified.next(true)
      })
    )
  }


  // Helpers ---------------------------------------------------------------

  getWeighIns(): IWeighIn[] {
    return Object.assign([], this.weigh_ins.getValue())
  }

  getCheckIns(): ICheckIn[] {
    return Object.assign([], this.checkInsProvider.getCheckIns())
  }

  setWeightLoss( weightLoss : number ) {
    this.weightloss = weightLoss
  }

  findLastWithField(field : string) : IBasicWeighIn|null {
    const lastWithField = _.findLast(this.getWeighIns(), (o) => { return o[field] != null })
    return lastWithField || {}
  }

  findFirstWithField(field : string) : IBasicWeighIn|null {
    const firstWithField = _.find(this.getWeighIns(), (o) => { return o[field] != null })
    return firstWithField || {}
  }

  calculateWeightloss() {
    this.weigh_ins$.subscribe( weighIns => {
      console.log('weigh ins', weighIns)
      let wl = 0
      this.average_weightloss = 0
      if (weighIns && weighIns.length >= 2) {
        const wi1 = _.first(weighIns).current_weight
        const wi2 = _.last(weighIns).current_weight
        wl = wi1 - wi2
        console.log(`wi1: ${wi1} - wi2: ${wi2} - wl: ${wl}`)
      }
      this.weightloss = Math.round(wl * 100) / 100
      this.checkInsProvider.calculateUsedWeeks()
      if (this.checkInsProvider.num_weeks > 0 ) {
        this.average_weightloss = this.weightloss / this.checkInsProvider.num_weeks
      }
      console.log(`Weightloss: ${this.weightloss} - Average: ${this.average_weightloss} - Weeks: ${this.checkInsProvider.num_weeks}`)
    })
  }


  /**
   * Attempts to refresh the weigh ins including a new pull from the Coachcare servers.
   * It also updates the value of {@link check_ins$}
   * @param clientId The client ID
   */
  refreshCoachcareWeighIns(clientId : number): Promise<[]> {
    const url = `${this.base_url}/clients/${clientId}/refresh_coach_care_weigh_ins`;
    return this.http.post<[]>(url, null).toPromise()
  }


  async displayGoalReminder(checkInId: number): Promise<void> {
    const standardCheckIns = this.getCheckIns().reverse().filter(ci => ci.type_of_weighin === 'standard' || ci.validated === false)
    const evalIndex = _.findIndex(standardCheckIns, { id: checkInId })

    if (evalIndex === 4) {
      const alert = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: 'Please review SMART goals, HBC and hand out referral card',
          image: 'celebration'
        },
        cssClass: 'small-modal'
      })

      await alert.present()
      await alert.onDidDismiss()
    }
  }


  async checkForCelebration(weighIn : IWeighIn): Promise<void> {
    if ( this.weightloss_pre_update < 20 && this.weightloss >= 20 ) {
      const alert = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: `Woohoo!  20 lbs down!`,
          subtitle: 'Please have them speak to their doctor about adjusting their medication dosages.',
          image: 'celebration'
        },
        cssClass: 'small-modal'
      })

      await alert.present()
      await alert.onDidDismiss()
    }
  }

  async checkForRepoCelebrations( checkInId : number ) {
    const checkIns = this.getCheckIns()
    const index = _.findIndex(checkIns, { id: checkInId })
    const purchasedWeeks = this.purchasesProvider.getPurchasedWeeks()
    const client = this.clientService.getClient()
    const program_status = client.program_status
    const standardCheckIns = checkIns.filter(ci => ci.type_of_weighin === 'standard' || ci.validated === false)
    const evalIndex = _.findIndex(standardCheckIns, { id: checkInId })
    const onFirstProgram = this.programsService.getPrograms().length === 1
    const finishedWeekOne = this.checkInsProvider.getCheckIns().length === 2
    const missingBeforePhoto = (client.before_front === null || client.before_side === null)

    // Only for active clients
    if ( program_status === 'active' ) {

      if ( finishedWeekOne && onFirstProgram && missingBeforePhoto ) {
        const beforePhotoAlert = await this.modalCtrl.create({
          component: AwakenModal,
          componentProps: {
            title: 'Before Photo',
            subtitle: 'Ask the client if they\'d like to take a before photo.'
          },
          cssClass: 'small-modal'
        })

        await beforePhotoAlert.present()
      }

      if (index !== 0 && purchasedWeeks !== 0) {
        const prevVisitPosition = index > 1 ? index - 1 : 0
        const prevVisitHalfway = ( prevVisitPosition / purchasedWeeks ) >= 0.5
        const currVisitPosition = index
        const currVisitHalfway = ( currVisitPosition / purchasedWeeks ) >= 0.5

        if ((!prevVisitHalfway && currVisitHalfway) || currVisitPosition === 4) {
          const halfwayAlert = await this.modalCtrl.create({
            component: AwakenModal,
            componentProps: {
              title: `Halfway done!  Please remind them to refer 3 friends!`,
              image: 'celebration'
            },
            cssClass: 'small-modal'
          })

          await halfwayAlert.present()
        }

        // With new setup next week is actually the re-eval week
        if ( (evalIndex + 1) === purchasedWeeks ) {
          const nextWeekReEval = await this.modalCtrl.create({
            component: AwakenModal,
            componentProps: {
              title: `Next week is their re-eval week!`,
              subtitle: `Please email or give a hard copy of the maintenance packet.`,
              image: 'alert',
              enableBackdropDismiss: true,
              allow_close: true
            },
            cssClass: 'small-modal'
          })

          await nextWeekReEval.present()
        }

        // With new setup this is actually the re-eval week
        if ( (evalIndex) === purchasedWeeks ) {
          const reEvalAlert = await this.modalCtrl.create({
            component: AwakenModal,
            componentProps: {
              title: `Re-Eval week!  Take a photo !`,
              subtitle: 'Please ask the client if they would like you to take an after photo.',
              image: 'alert',
              enableBackdropDismiss: true,
              allow_close: true
            },
            cssClass: 'small-modal'
          })

          await reEvalAlert.present()
        }

        if ( index % 5 === 0 ) {
          const fiveWeekAlert = await this.modalCtrl.create({
            component: AwakenModal,
            componentProps: {
              subtitle: `Woohoo!  Another 5 weeks in the books!`,
              title: 'Please review their healthy body composition and give them referral cards.',
              image: 'celebration'
            },
            cssClass: 'small-modal'
          })

          await fiveWeekAlert.present()
        }
      }
    }
  }
}
